import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = class extends React.Component {
  componentDidMount() {
    let root = document.documentElement;
    root.style.setProperty('--tp-navbar-color', '#374836');
	}

  render() {
    return (<Layout>
      <div className="container simple-page mt-6 mb-6">
        <h1>404</h1>
        <p>Sorry, deze pagina is niet beschikbaar!</p>
      </div>
    </Layout>);
  }

}

export default NotFoundPage;
